import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container, Row, Col } from 'react-bootstrap';
import TopCapabilityContent from "./top-capability-content";

const interval = 4000;

const TopCapabilities = () => {

  const { imageDatas } = useStaticQuery(
    graphql`
      query {
        imageDatas: allTopCapabilitiesJson {
          edges {
            node {
              image {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 960)
                }
              }
              id
              objectPosition
            }
          }
        }
      }
    `
  );
  const datas = imageDatas.edges;
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const changeSlide = () => {
      const targetIndex = (datas.length - 1 <= index) ? 0 : index + 1;
      setIndex(targetIndex);
    };
    const id = setTimeout(changeSlide, interval);
    return (() => {
      clearTimeout(id);
    });
  }, [index, datas.length]);

  return (
    <React.Fragment>
      <Container fluid className="justify-content-center capabilitiesHeader">
        <StaticImage src="../../images/top/capabilities_1.jpg" layout="constrained" alt="" objectPosition="50% 50%" />
        <div className="capabilitiesBg">
          <h2>CAPABILITIES</h2><h2>CAPABILITIES</h2><h2>CAPABILITIES</h2><h2>CAPABILITIES</h2><h2>CAPABILITIES</h2>
        </div>
      </Container>
      <Container className="capabilitiesLead">
        <div className="leadImg">
          <div className="leadSlideshow">
            <GatsbyImage image={getImage(datas[index].node.image)} layout="constrained" alt="" objectPosition={datas[index].node.objectPosition} />
          </div>
        </div>
        <Row>
          <Col>
            <div>
              <h2>Capabilities</h2>
              <p>
                Creative teams that<br />comprehensively design<br />spaces and experiences<br />based on imaging
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="capabilitiesBody">
        <Row>
          <TopCapabilityContent number="01" head="Production Work">
            <p>
              The scope of our work includes imaging projects, advertisements, and event imaging for a variety of media, as well as planning and production of documentary films and all design work related to concept imaging, installations, and other imaging projects.
            </p>
          </TopCapabilityContent>
          <TopCapabilityContent number="02" head="Creative Planning">
            <p>
              With in-house professional staff for production, editing, and photography and a network of partner companies in Japan and overseas, we assemble the optimal team for the task to meet customers' requests.
            </p>
          </TopCapabilityContent>
          <TopCapabilityContent number="03" head="Experiential Design">
            <p>
              We create the entire environment surrounding our imaging, pursuing new possibilities in expression through imaging with the creation of space designs and experience designs that are felt as well as seen.
            </p>
          </TopCapabilityContent>
          <Col>
            <StaticImage src="../../images/top/capabilities_3.jpg" alt="" layout="constrained" />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TopCapabilities;
